import { IPosition } from "./IPosition.interface";

export const freelance: IPosition = {
  name: "Freelancer, Cairo",
  fromTo: "Nov 2010 — Nov 2012",
  title: "Lead Software Engineer",
  details: [
    "Started my career as a Web Engineer freelancer",
    "Worked with multiple Client Products in Saudi Arabia",
    "Tech: Php (Laravel) , Asp.Net"
  ]
};
