import { IPosition } from "./IPosition.interface";

export const emerging: IPosition = {
  pageBreakBefore: true,
  name: "Emerging Media, London (Remote)",
  fromTo: "Nov 2012 — Nov 2013",
  title: "Lead Software Engineer",
  details: [
    "Led a team of 2 Web Engineers",
    "Worked on internal & client web applications using REST API, Social Networks SDKs, and Facebook Canvas APP",
    "worked on lead generation landing pages and Google Maps API",
    "Tech: PHP (Laravel, CodeIgniter) & MYSQL",
  ],
};
