import { IPosition } from "./IPosition.interface";

export const eventtus: IPosition = {
  name: "Eventtus, Cairo",
  fromTo: "Nov 2013 — Oct 2014",
  title: "Lead Software Engineer",
  details: [
    "Led the engineering team of 4 Web Engineers in a start-up environment",
    "Own the development process from end to end",
    "Redesign the customer-facing application and event creator panel",
    "Ensure the operational excellence and Revamped the deployment of our application",
    "introduced CI/CD with GitHub, AWS (EC2, S3), Cloudflare and NewRelic",
    "Integrated with online payment systems 'Payfort Payment Gateway'",
    "Tech : PHP (Symfony2), MongoDB, BackboneJS, MarionetteJS",
  ],
};
