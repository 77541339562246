import { IPosition } from "./IPosition.interface";

export const arkony: IPosition = {
  name: "Arkony, Istanbul",
  fromTo: "Oct 2014 — April 2015",
  title: "Senior Software Engineer",
  details: [
    "Joined a Start-up environment that focuses on community and blogging experience",
    "Build a medium-like editor for the blog application",
    "Build the frontend of messaging interface using WebSockets and Pub/Sub",
    "Tech : BackboneJS, MariontteJS & CoffeeScript",
  ],
};
