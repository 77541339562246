import { IPosition } from "../positions/IPosition.interface";

export const tedx: IPosition = {
  name: "TEDxCairo",
  fromTo: "Oct 2013 — OOct 2014",
  title: "Innovation Member",
  details: [
    "worked on all software engineering and IT setup for the yearly conference, e.g., Website and Applications for Registration and attendees",
  ],
};
