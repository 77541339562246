import React from "react";
import { jobs } from "../data/positions";
import { volunteer } from "../data/volunteer";

export enum Section {
  work = "work",
  volunteer = "volunteer",
}

type WorkProps = { section: Section };

export function WorkSection({ section }: WorkProps) {
  const sectionTitle = section === Section.work ? "—Work" : "—Volunteer";
  const data = section === Section.work ? jobs : volunteer;
  return (
    <div className="section section-work">
      <h2 className="title">{sectionTitle}</h2>
      <div className="set">
        {data.map(
          ({ name, fromTo, details, title, pageBreakBefore }, index) => (
            <>
              {pageBreakBefore && <div className="page-break" />}
              <div className="item" key={index}>
                <h3>
                  <span className="company l">{name}</span>
                  <span className="date r">{fromTo}</span>
                </h3>
                <span className="job_title">{title}</span>
                <ul className="info">
                  {details.map((data, dIndex) => (
                    <li
                      key={dIndex}
                      dangerouslySetInnerHTML={{ __html: data }}
                    ></li>
                  ))}
                </ul>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
}
