import React from "react";

export function ObjectiveSection() {
  return (
    <div className="section">
      <h2 className="title">—Objective</h2>

      <div className="set">
        <div className="item">
          <div className="info">
            Seeking a position of Engineering Manager to put 10+ years of
            software development into use to help the business meet strategic
            and operational goals by identifying opportunities using new
            technologies. <br /> Possess expertise in Software Engineering and
            Web development and proven ability to manage a team, complex tasks,
            and projects.
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
