import { klarna } from "./klarna";
import { zalandoManager } from "./zalando-manager";
import { zalando } from "./zalando";
import { ligadigital } from "./ligadigital";
import { arkony } from "./arkony";
import { eventtus } from "./eventtus";
import { emerging } from "./emerging-media";
import { freelance } from "./freelance";
import { IPosition } from "./IPosition.interface";

export const jobs: IPosition[] = [
  klarna,
  zalandoManager,
  zalando,
  ligadigital,
  arkony,
  eventtus,
  emerging,
  freelance,
];
