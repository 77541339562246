import React from "react";

export function PhotoSection() {
  return (
    <>
      <div id="photo">
        <img src={process.env.PUBLIC_URL + "/img/profile.jpg"} alt="" />
      </div>
      <div className="header photo">
        <h1 id="title">Ahmed ElSawaf</h1>

        <div className="basic-info" style={{ marginBottom: 16 }}>
          <p className="email">
            <span className="value">
              <a href="mailto:ahmed@bondoq.me" target="_top">
                ahmed@bondoq.me
              </a>
            </span>
          </p>

          <p className="website">
            <span className="value">
              <a href="http://bondoq.me" rel="nofollow">
                http://bondoq.me
              </a>
            </span>
          </p>

          <p className="phone">
            <span className="value">(+49) 1575 656 1656</span>
          </p>
        </div>
        <div className="icons-social h1" style={{display: 'flex', gap: 15}}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/bondoq"
          >
            <i className="fa-lg fab fa-github"></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/_bondoq"
          >
            <i className="fa-lg fab fa-twitter"></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://linkedin.com/in/bondoq"
          >
            <i className="fa-lg fab fa-linkedin"></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://medium.com/@bondoq"
          >
            <i className="fa-lg fab fa-medium"></i>
          </a>
        </div>
      </div>
    </>
  );
}
