import { IPosition } from "./IPosition.interface";

export const klarna: IPosition = {
  name: "Klarna, Berlin",
  fromTo: "Feb 2021 — Now",
  title: "Engineering Manager",
  details: [
    "Led cross-functional Engineering team(s) to build the core dataset for Merchants (Stores and Brands), Currently used by 25+ teams in Klarna",
    "Led the team through the development of the Back-office and scrapping automations to ensure High quality and coverage dataset for our customers in 20+ markets",
    "Ensure high operational standards around incidents, and post-mortems for new and legacy systems, with zero downtime during Black Friday",
    "Contributed to Hiring and onboarding our domain's Product Managers and Engineering Managers",
  ],
};
