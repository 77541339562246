import { IPosition } from "./IPosition.interface";

export const zalando: IPosition = {
  pageBreakBefore: true,
  name: "Zalando SE, Berlin",
  fromTo: "April 2017 — Sep 2019",
  title: "Senior Software Engineer",
  details: [
    "Joined Zalando Product Details team",
    "Hiring Hero for three years with 100 Frontend Interviews total",
    'Contributed to the migration and redesign of Product details page using then internal <a href="https://engineering.zalando.com/posts/2018/12/front-end-micro-services.html">Architecture</a>',
    "Contributed to migration for our premise on K8s, providing a operational cheatsheet, and Playbooks",
    "Contributed to multiple A/B Tests for better customer experience and company-wide Initiatives",
    "Participated in the on-call rotation role for our department",
    "",
    "Joined Zalando-Lounge Frontend team",
    "Contributed to migrating the central premise from Legacy Symfony(PHP) application to nodeJS/React.",
    "Built a centralized backend tracking service integrated with Google analytics and all the micro-frontend applications used for customer data using NodeJS/Redis.",
    "Maintained a private fork from our Reverse proxy/routes Skipper and Built a simple UI for managing our routes",
    "Tech: ReactJS, NodeJS, Golang, Redis, Typescript, Kubernetes, Legacy Stack: PHP(Symfony), SOAP, CoffeeScript",
  ],
};
