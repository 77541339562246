import { IPosition } from "./IPosition.interface";

export const ligadigital: IPosition = {
  name: "LIGADIGITAL AG, Stuttgart",
  fromTo: "April 2015 — March 2017",
  title: "Senior Software Engineer",
  details: [
    'Led the engineering of the  <a href="https://www.brandretailos.com/"> BrandRetail OS </a> Frontend development.',
    "Built a web-based image manipulation tool that focuses on layering, clipping, zooming, history, and grid rulers for mapping 3D articles placed into 2D / 3D environment.",
    "Built services to support BrandRetail OS for Reports generation in PDF and Image generation with high resolution and optimized runtime and size.",
    "Built internal tools for monitoring Systems and versions Deployment on different.",
    "Tech: EmberJS, NodeJS, RxJS, Redux, ReactJS, PouchDB, FabricJS, imageMagick",
  ],
};
