import React from "react";

export function Home() {
  return (
    <main>
      <div className="intro">Hi, I'm Ahmed</div>
      <div className="tagline">Engineering Manager <strong style={{backgroundColor: "rgba(0,0,0, .12)", borderRadius: 3}}>@Zalando</strong></div>
      <div className="tagline">macOS — FC Barcelona </div>
      <div className="icons-social">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/bondoq"
        >
          <i className="fab fa-github"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/_bondoq"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://linkedin.com/in/bondoq"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://medium.com/@bondoq"
        >
          <i className="fab fa-medium"></i>
        </a>
      </div>
    </main>
  );
}
