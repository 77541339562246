import React from "react";
import { WorkSection, Section } from "../components/work-section";
import { PhotoSection } from "../components/photo-section";
import { ObjectiveSection } from "../components/objective-section";
import { EducationSection } from "../components/education-section";
import { InterestsSection } from "../components/interest-section";

export function CV() {
  return (
    <>
      <PhotoSection />
      <ObjectiveSection />
      <WorkSection section={Section.work} />
      <WorkSection section={Section.volunteer} />
      <EducationSection />
      <InterestsSection />
    </>
  );
}
