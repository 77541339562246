import React from "react";

export function InterestsSection() {
  return (
    <div className="section section-interests">
      <h2 className="title">—Interests</h2>

      <div className="set">
        <div className="item">
          <span className="info">
            <ul>
              <li>PC, PS5 Games</li>
              <li>Football, Basketball, Ping Pong</li>
              <li>Marvel Movies, Music, Reading, Coffee</li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
}
