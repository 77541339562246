import { IPosition } from "../positions/IPosition.interface";

export const support: IPosition = {
  name: "Support FCIS",
  fromTo: "Sep 2010 — Aug 2012",
  title: "IT Member, Head and Advisor",
  details: [
    "Found a student organization mainly to fill the GAP between university and work-life",
    "Worked in the IT department for 4 years building website, mobile application",
    "Mentoring junior students, Presenting a couple of web development sessions",
  ],
};
