import React from "react";

export function EducationSection() {
  return (
    <div className="section section-education">
      <h2 className="title">—Education</h2>

      <div className="set">
        <div className="item">
          <h3>
            <span className="course l">Computer &amp; Information Science</span>
            <span className="date r">Sep 2007 &mdash; Jun 2011</span>
          </h3>
          <span className="school">Ain Shams University, Cairo</span>
        </div>
        <div className="item">
          <h3>
            <span className="course l">Saint Fatima School</span>
            <span className="date r">Sep 2005 &mdash; Jun 2007</span>
          </h3>
          <span className="school">High School</span>
        </div>
      </div>
    </div>
  );
}
