import { IPosition } from "./IPosition.interface";

export const zalandoManager: IPosition = {
  name: "Zalando SE, Berlin",
  fromTo: "Sep 2019 — Sep 2020",
  title: "Engineering Lead",
  details: [
    'Led a cross-functional Engineering team <a href="https://www.youtube.com/watch?v=qwDn08aOtZ8">Appcraft</a> Team to build the new backend driven UI platform for Zalando mobile.',
    "Rolled out the new mobile app home and outfits and 10 more screens using the new technology",
    "Drove the team mission, vision, strategy, and ways of working",
    "Ensure high operational standards around incidents and post-mortems.",
    "Ensure good dynamics within the team by hiring new candidates and handling underperformance cases",
  ],
};
